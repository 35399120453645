import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { Card } from "reactstrap";
import classnames from "classnames";
import {
    CheckIcon,
    RefreshIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setConfigOptions,
    setIsConfigOptionsActive,
    setIsDesignConfigOptionsActive,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
    setIsAllergenActive,
    setIsCustomerAccountActive,
    setIsPageHeaderActive,
    setIsPRMActive,
    setIsHelpActive,
    setIsCaloryActive,
    setIsStateChanged,
    store,
    setIsEmailActive,
    setIsConfirmationEmailActive,
    setIsOrderTakingPromoCodeActive,
    setIsPagePromoCodeActive,
    setIsPageUserAccountActive,
    setIsPageAccountActive,
    setIsUserAccountOrderTakingActive,
} from "@store";
import kioskSettingStore from "@pages/GeneralConfig/store";
import { store as storeGlobal } from "@components/VerticalLayout/store";
import { getImageContentById } from "@helpers/general";
import { Uploader } from "@components/Uploader";

type OptionItemPropsType = {
    option: OptionsItemType;
    index: number;
    setOptions: React.Dispatch<React.SetStateAction<OptionsItemType[]>>;
    isKioskSetting?: any;
};

export function OptionItem({
    option,
    index,
    setOptions,
}: OptionItemPropsType): JSX.Element {
    const { t } = useTranslation();
    const { isKioskSetting } = useSnapshot(kioskSettingStore);
    const { mainColor } = useSnapshot(store);
    const { fideltyType } = useSnapshot(storeGlobal);

    const {
        project: { files: images, template },
        imageKey,
        isResetOneImageButtonClicked,
        isCustomerAccountActive,
        selectedOptionsLanguage,
    } = useSnapshot(generalConfigStore);

    const [cardImageKey, setCardImageKey] = React.useState<string>("");

    const imageContent = getImageContentById(
        option.id,
        images,
        selectedOptionsLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    const selectedSaleMethodDeliveryExist = (template.content
        .salesMethods as ProjectContentItemType).items.filter(
        (sale: any) => sale.name === "Delivery"
    )[0];

    function handleCheckIconOnClickEvent(index: number) {
        setOptions((prevState: OptionsItemType[]): OptionsItemType[] => {
            let newState = [...prevState];

            newState = newState.map(
                (item: OptionsItemType, itemIndex: number) => {
                    if (item.title === "Customer account") {
                        if (
                            ((template.pages.subSteps["customerBenefit"] as any)
                                .options as CustomerBenefitOptionsType)
                                .paymentWithLoyaltyBalance === false
                        ) {
                            setIsOrderTakingPromoCodeActive(!item.active);
                            setIsPagePromoCodeActive(!item.active);
                        }
                        if (!item.active === false) {
                            setIsPageAccountActive(false);
                            setIsPageUserAccountActive(false, "orderTaking");
                        }
                    }
                    if (itemIndex === index) {
                        return {
                            ...item,
                            active: !item.active,
                        };
                    }

                    return { ...item };
                }
            );

            setConfigOptions(newState);

            if (newState.filter((item) => item.active === true).length === 0) {
                setIsConfigOptionsActive(false);
                setIsDesignConfigOptionsActive(false);
            }

            return newState;
        });
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            const localContent = image.content as ImageItemLanguagesType;

            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [selectedOptionsLanguage]: {
                        ...localContent[selectedOptionsLanguage],
                        path: localContent[selectedOptionsLanguage]
                            .defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }
        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    return (
        <div>
            {!["Allergen", "Promo code", "Calorie", "Usual"].includes(
                (option as OptionsItemType).title
            ) ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                        gap: "10px",
                        width: "fit-content",
                        opacity: option.active === false ? "40%" : "",
                    }}
                >
                    <div
                        className="d-flex align-items-center justify-content-start align-items-center text-muted pl-4 w-100"
                        style={{ gap: "10px" }}
                    >
                        {/* {!isKioskSetting ? (
                    <div>
                        <div id="help-icon" className="pl-3">
                            <HelpIcon height={25} width={25} />
                        </div>
                        <UncontrolledTooltip
                            placement="right"
                            target="help-icon"
                            className="cursor__clz"
                        >
                            {t("Help")}
                        </UncontrolledTooltip>
                    </div>
                ) : null} */}
                        <span
                            className="cursor__clz"
                            style={{
                                font:
                                    "normal normal normal 15px/20px Nunito Sans",
                            }}
                        >
                            {option.languages[selectedOptionsLanguage].name}
                        </span>
                    </div>
                    <Card
                        className={classnames(
                            "ml-1 mt-1 cmn_chk_selectButton",
                            {
                                "disable-account-creation-items__clz":
                                    ((selectedSaleMethodDeliveryExist !==
                                        undefined &&
                                        selectedSaleMethodDeliveryExist.active ===
                                            true) ||
                                        fideltyType === 0) &&
                                    option.title === "Customer account",
                            }
                        )}
                        style={{
                            borderBottom: option.active
                                ? `${mainColor} 10px solid`
                                : "",
                            width: "250px",
                            height: "220px",
                            borderRadius: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "grid",
                                gridTemplateRows: "0.9fr auto",
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                                    height: "100%",
                                }}
                            >
                                <CheckIcon
                                    onClick={() => {
                                        handleCheckIconOnClickEvent(index);
                                        if (option.title === "Allergen") {
                                            setIsAllergenActive(!option.active);
                                        } else if (
                                            option.title === "Customer account"
                                        ) {
                                            setIsCustomerAccountActive(
                                                !option.active
                                            );
                                            if (option.active) {
                                                setIsEmailActive(false);
                                                setIsConfirmationEmailActive(
                                                    false
                                                );
                                            }
                                            setIsStateChanged(true);
                                        } else if (
                                            option.title === "PRM/Child"
                                        ) {
                                            setIsPRMActive(!option.active);
                                        } else if (
                                            option.title === "Ask for help"
                                        ) {
                                            setIsHelpActive(!option.active);
                                        } else if (option.title === "Calorie") {
                                            setIsCaloryActive(!option.active);
                                        }
                                        if (
                                            [
                                                !option.active,
                                                isCustomerAccountActive,

                                                (template.pages.ways[
                                                    "orderTaking"
                                                ] as OrderTakingPageType).header
                                                    .userAccount.active,
                                            ].every(
                                                (currentValue) =>
                                                    currentValue === false
                                            )
                                        ) {
                                            setIsPageHeaderActive(
                                                false,
                                                "orderTaking"
                                            );
                                        } else {
                                            setIsPageHeaderActive(
                                                true,
                                                "orderTaking"
                                            );
                                        }
                                    }}
                                    className={classnames(
                                        "ml-1 mt-1 cmn_chk_selectButton",
                                        {
                                            "disable-account-creation-items__clz":
                                                ((selectedSaleMethodDeliveryExist !==
                                                    undefined &&
                                                    selectedSaleMethodDeliveryExist.active ===
                                                        true) ||
                                                    fideltyType === 0) &&
                                                option.title ===
                                                    "Customer account",
                                        }
                                    )}
                                    height={45}
                                    width={45}
                                    style={{
                                        fill: option.active
                                            ? mainColor
                                            : "#BCBCBC",
                                        cursor: "pointer",
                                    }}
                                />
                                <div
                                    className={classnames(
                                        "d-flex justify-content-center align-items-end",
                                        {
                                            "disable-account-creation-items__clz":
                                                selectedSaleMethodDeliveryExist !==
                                                    undefined &&
                                                selectedSaleMethodDeliveryExist.active ===
                                                    true &&
                                                option.title ===
                                                    "Customer account",
                                        }
                                    )}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        handleCheckIconOnClickEvent(index);
                                        if (option.title === "Allergen") {
                                            setIsAllergenActive(!option.active);
                                        } else if (
                                            option.title === "Customer account"
                                        ) {
                                            setIsCustomerAccountActive(
                                                !option.active
                                            );
                                            if (option.active) {
                                                setIsEmailActive(false);
                                            }
                                            setIsStateChanged(true);
                                        } else if (
                                            option.title === "PRM/Child"
                                        ) {
                                            setIsPRMActive(!option.active);
                                        } else if (
                                            option.title === "Ask for help"
                                        ) {
                                            setIsHelpActive(!option.active);
                                        } else if (option.title === "Calorie") {
                                            setIsCaloryActive(!option.active);
                                        }
                                        if (
                                            [
                                                !option.active,
                                                isCustomerAccountActive,

                                                (template.pages.ways[
                                                    "orderTaking"
                                                ] as OrderTakingPageType).header
                                                    .userAccount.active,
                                            ].every(
                                                (currentValue) =>
                                                    currentValue === false
                                            )
                                        ) {
                                            setIsPageHeaderActive(
                                                false,
                                                "orderTaking"
                                            );
                                        } else {
                                            setIsPageHeaderActive(
                                                true,
                                                "orderTaking"
                                            );
                                        }
                                    }}
                                >
                                    <img
                                        alt=""
                                        key={index}
                                        src={imageSrc}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    />
                                </div>
                                {!isKioskSetting ? (
                                    <StyledIconButton
                                        title={t("Reset to default image")}
                                        disabled={option.active === false}
                                        outline={true}
                                        variant="danger"
                                        className="m-0 p-0 cmn_icn_resetPhoto"
                                        icon="RefreshIcon"
                                        style={{ height: "20%" }}
                                        onClick={() => {
                                            handleResetImagesOnClickEvent(
                                                option.id
                                            );
                                            setIsResetOneImageButtonClicked(
                                                true
                                            );
                                        }}
                                    >
                                        <RefreshIcon height={20} width={20} />
                                    </StyledIconButton>
                                ) : null}
                            </div>
                            {!isKioskSetting ? (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <fieldset
                                        disabled={option.active === false}
                                    >
                                        <div className="d-flex justify-content-center align-items-center index-shadow__clz cmn_icn_uploadPhoto">
                                            <Uploader
                                                key={
                                                    isResetOneImageButtonClicked ===
                                                    false
                                                        ? imageKey
                                                        : cardImageKey
                                                }
                                                id={option.id}
                                                activeLanguage={
                                                    selectedOptionsLanguage
                                                }
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            ) : null}
                        </div>
                    </Card>
                </div>
            ) : null}
        </div>
    );
}
