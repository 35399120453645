import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { store as aggregator } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    data: any;
    setChoiceAggregator: Function;
    PosData?: any;
    setIsEdited: Function;
    setWebhook: Function;
    setRemark: Function;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    data,
    setChoiceAggregator,
    PosData,
    setIsEdited,
    setRemark,
    setWebhook,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { isArchived, aggregatorFranchise } = useSnapshot(aggregator);
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");
    
    async function AssociateAggregator() {
        toast.dismiss();
        let objectData =
            aggregatorFranchise.othersData.allData.data[
                aggregatorFranchise.othersData.allData.data.findIndex(
                    (el: any) =>
                        el[t("Display name")] === data.designation &&
                        el[t("Aggregator")] === data.choiceAggregator &&
                        ((el[t("Group")] === data.group &&
                            data.choiceAggregator.startsWith(
                                t("Aggregator Aures Konnect")
                            )) ||
                            !data.choiceAggregator.startsWith(
                                t("Aggregator Aures Konnect")
                            ))
                )
            ];

        let aggregator = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [objectData.Id]: {
                    aggregator: PosData.aggregator,
                    webhook: PosData.webhook,
                    note: PosData.note,
                    isActive: PosData.isActive,
                    isArchived: false,
                    shopId: shopID,
                    state: PosData.state,
                    stateShop: PosData.stateShop,
                },
            },
            isShop: true,
        };
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(aggregator),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Aggregator has been successfully assigned"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);
                        clearMultiCustomSelectionData();
                        clearData();
                        setIsEdited(false);
                        setChoiceAggregator("");
                        setRemark("");
                        setWebhook("");
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {!isArchived
                        ? t(
                              "The Aggregator already exist in the franchise. Would you like to associate it with this store?"
                          )
                        : t(
                              "Attention ! the Aggregator is already archived at the franchise level"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsEdited(false);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isArchived) {
                            AssociateAggregator();
                        }

                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
