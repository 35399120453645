import React from "react";
import classnames from "classnames";
import { CheckboxIcon, EyeIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import ErrorToast from "@components/Common/ErrorTost";
import { mutate } from "swr";

import { store as fidelity } from "../../../../components/VerticalLayout/store";
import store, {
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setSortedVisibilityEdited,
    setNumberArchived,
    setNumberActive,
    setGlobalDataIsUpdated,
} from "../store";

import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";

export function CustomIcons({
    e,
    setShopId,
    setFidelity,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isFidelityArchived,
    setIsAddFidelityClicked,
    isAddFidelityClicked,
    selectedRows,
    setLocalFilterActive,
    setIsModalOpenedSupport,
    setIsConsult,
}: any) {
    const { t } = useTranslation();
    const {
        shopsFidelityData,
        archivedFidelityFranchise,
        isActionsClicked,
        isEdited,
        modify,
        fidelityData,
        numberArchived,
        numberActive,
        isSupportCodeValid,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(fidelity);
    // const [fidelityState, setFidelityState] = React.useState<boolean>(
    //     e.selectedRow.isActive
    // );
     const [isFidelityClicked, setIsFidelityClicked] = React.useState<boolean>(
         false
     );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociateFidelity();
        dataArchive = ArchiveFidelity();
    } else {
        dataArchive = ArchiveFidelityFranchise();
    }

    function AssociateFidelity() {
        let idsAssociated: any = [];
        if (shopsFidelityData.data !== undefined) {
            shopsFidelityData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        idsAssociated.push(elt.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchiveFidelity() {
        let idsAssociated: any = [];
        if (shopsFidelityData.data !== undefined) {
            shopsFidelityData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchiveFidelityFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFidelityFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedFidelityFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activeFidelity() {
        toast.dismiss();
          if (e.selectedRow.fidelityChoiceIndex === 1) {
              const index = e.selectedRow.isArchived.findIndex(
                  (shop: any) =>
                      shop.shopId === `${e.selectedRow.affectedShop}` ||
                      shop.shopId === e.selectedRow.affectedShop
              );
              if (index !== -1) {
                  e.selectedRow.isArchived[index].status = false;
              }
          }
        let dataFidelity = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: {
                [e.selectedRow.Id]: {
                    designation: e.selectedRow[t("Display name")],
                    type: e.selectedRow[t("Loyalty")],
                    number_fidelity_points:
                        e.selectedRow.number_fidelity_points,
                    money_correspondence: e.selectedRow.moneyCorrespondence,
                    devise: e.selectedRow.Devise,
                    pays: e.selectedRow.Pays,
                    authentication_mode: e.selectedRow.authentication_mode,
                    sortedVisibility: e.selectedRow.sortedVisibility,
                    Login: e.selectedRow.Login,
                    password: e.selectedRow[t("Password")],
                    note: e.selectedRow[t("Remark")],
                    isActive: false,
                    isArchived:   e.selectedRow.fidelityChoiceIndex !== 1
                         ? false
                         : JSON.stringify(e.selectedRow.isArchived),
                    shopId: e.selectedRow.shopId,
                    dashboardLink: e.selectedRow["Dashboard Link"],
                    user: e.selectedRow.user,
                    apiKey: e.selectedRow["API key"],
                    auresKonnectGroup: e.selectedRow[t("Group")],
                    isCentralizationActive: e.selectedRow[t("State")],
                    isVisibiliteActive: e.selectedRow[t("isVisibiliteActive")],

                    fidelityChoiceIndex: e.selectedRow.fidelityChoiceIndex,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataFidelity),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The fidelity setting has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setGlobalDataIsUpdated!(true)
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    // async function handleStateChange() {
    //     let id = e.selectedRow.Id;
    //     let obj = {
    //         userId: userID,
    //         shopId: [e.selectedRow.shopId],
    //         franchiseId: franchiseID,
    //         operatorId: operatorID,
    //         uuidUser: uuidUser,
    //         isShop: false,
    //         data: {
    //             [id]: {
    //                 designation: e.selectedRow[t("Display name")],
    //                 type: e.selectedRow[t("Loyalty")],
    //                 number_fidelity_points:
    //                     e.selectedRow.number_fidelity_points,
    //                 money_correspondence: e.selectedRow.moneyCorrespondence,
    //                 devise: e.selectedRow.Devise,
    //                 pays: e.selectedRow.Pays,
    //                 authentication_mode: e.selectedRow.authentication_mode,
    //                 sortedVisibility: e.selectedRow.sortedVisibility,
    //                 Login: e.selectedRow.Login,
    //                 password: e.selectedRow[t("Password")],
    //                 note: e.selectedRow[t("Remark")],
    //                 isArchived: false,
    //                 isActive: !fidelityState,
    //                 shopId: e.selectedRow.shopId,
    //                 dashboardLink: e.selectedRow["Dashboard Link"],
    //                 user: e.selectedRow.user,
    //                 apiKey: e.selectedRow["API key"],
    //                 auresKonnectGroup: e.selectedRow[t("Group")],
    //                 isCentralizationActive: e.selectedRow[t("State")],
    //                 isVisibiliteActive: e.selectedRow[t("isVisibiliteActive")],

    //                 fidelityChoiceIndex: e.selectedRow.fidelityChoiceIndex,
    //             },
    //         },
    //     };

    //     let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
    //     mutate(
    //         apiUrl,
    //         await fetch(apiUrl, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(obj),
    //             method: "POST",
    //         })
    //             .then((response) => response.json())
    //             .then((result) => {
    //                 if (result.error) {
    //                     throw Error(result.message);
    //                 }

    //                 toast.success(
    //                     !fidelityState
    //                         ? `${t(
    //                               "The fidelity setting has been successfully activated"
    //                           )}`
    //                         : `${t(
    //                               "The fidelity setting has been successfully deactivated"
    //                           )}`,

    //                     {
    //                         position: toast.POSITION.TOP_CENTER,
    //                         autoClose: 2000,
    //                         theme: "colored",
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         icon: (
    //                             <CheckboxIcon
    //                                 height={25}
    //                                 width={25}
    //                                 fill="white"
    //                             />
    //                         ),
    //                     }
    //                 );
    //                 setDataIsUpdated!(true);
    //             })
    //             .catch((error: any) => {
    //                 toast.error(`${t("There's an error")!}`, {
    //                     position: toast.POSITION.TOP_CENTER,
    //                     autoClose: 2000,
    //                     theme: "colored",
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                 });
    //             })
    //     );
    // }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                if (!isAddFidelityClicked) {
                                                    setIsAddFidelityClicked(
                                                        !isAddFidelityClicked
                                                    );
                                                }

                                                setShopId(e.selectedRow.shopId);
                                                setFidelity(e.selectedRow);
                                                setLocalFilterActive(false);
                                            });
                                        } else {
                                            if (!isAddFidelityClicked) {
                                                setIsAddFidelityClicked(
                                                    !isAddFidelityClicked
                                                );
                                            }

                                            // setShopId(e.selectedRow.shopId);
                                            setFidelity(e.selectedRow);
                                            setSortedVisibilityEdited(
                                                e.selectedRow.sortedVisibility
                                            );
                                            setLocalFilterActive(false);
                                        }
                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={isFidelityArchived ? "" : t("Edit")}
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    if (!isAddFidelityClicked) {
                                                        setIsAddFidelityClicked(
                                                            !isAddFidelityClicked
                                                        );
                                                    }

                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setFidelity(e.selectedRow);
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                if (!isAddFidelityClicked) {
                                                    setIsAddFidelityClicked(
                                                        !isAddFidelityClicked
                                                    );
                                                }

                                                // setShopId(e.selectedRow.shopId);
                                                setFidelity(e.selectedRow);
                                                setSortedVisibilityEdited(
                                                    e.selectedRow
                                                        .sortedVisibility
                                                );
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isFidelityArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {/* {oneShop && (
                                <Tooltip
                                    title={
                                        isFidelityArchived
                                            ? ""
                                            : fidelityState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={fidelityState}
                                            onChange={async () => {
                                                if (
                                                    e.selectedRow
                                                        .fidelityChoiceIndex ===
                                                    1
                                                ) {
                                                    setIsModalOpenedSupport(
                                                        true
                                                    );
                                                } else {
                                                    setFidelityState(
                                                        !fidelityState
                                                    );
                                                    handleStateChange();
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "pointer__clz mdv_icn_BTenableDisable",
                                                {
                                                    "not-allowed-icon__clz": isFidelityArchived,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )} */}
                            {!isFidelityArchived ? (
                                <Tooltip
                                    title={
                                        e.selectedRow[t("State")] === true &&
                                        oneShop
                                            ? ""
                                            : t("Archive")
                                    }
                                >
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                              
                                                setIsFidelityClicked(true);
                                                if (
                                                    e.selectedRow
                                                        .fidelityChoiceIndex ===
                                                    1
                                                ) {
                                                      
                                                      if (
                                                          dataAssociate.includes(
                                                              e.selectedRow.Id
                                                          )
                                                      ) {
                                                          ErrorToast(
                                                              `${t(
                                                                  "Please note that you cannot archive an item assigned to stores."
                                                              )!}`
                                                          );
                                                      } else {
                                                          setIsModalOpenedSupport(
                                                              true
                                                          );
                                                      }
                                                } else if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddFidelityClicked(
                                                            false
                                                        );
                                                        if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setIsArchiveModalOpened(
                                                                true
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddFidelityClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setIsArchiveModalOpened(
                                                            true
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                oneShop ? "mr-2" : "ml-3",
                                                {
                                                    "not-allowed-icon__clz":
                                                        e.selectedRow[
                                                            t("State")
                                                        ] === true && oneShop,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if(numberActive >= 1 && !oneShop){
                                                    ErrorToast(
                                                        t(
                                                        "Attention, a franchise cannot have more than one setting fidelity"
                                                        )
                                                    );
                                                }else
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    activeFidelity();
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else if (
                                                    fidelityData.othersData.allFidelity.data.filter(
                                                        (element: any) => {
                                                            if (
                                                                element.shopId ===
                                                                e.selectedRow
                                                                    .shopId
                                                            ) {
                                                                return element;
                                                            }
                                                            return true;
                                                        }
                                                    )[0] !== undefined &&
                                                    fidelityData.othersData.allFidelity.data.filter(
                                                        (element: any) => {
                                                            if (
                                                                element.shopId ===
                                                                e.selectedRow
                                                                    .shopId
                                                            ) {
                                                                return element;
                                                            }
                                                            return true;
                                                        }
                                                    )[0].subRows.length !== 0 &&
                                                    oneShop
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,a shop cannot have more than one fidelity settings"
                                                        )
                                                    );
                                                } else {
                                                    activeFidelity();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }

                                                setFidelity(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-3 cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    {/* <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (
                                        e.selectedRow.fidelityChoiceIndex === 1
                                    ) {
                                        setIsModalOpenedSupport(true);
                                    } else if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddFidelityClicked(false);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setSelectedFidelity([
                                                {
                                                    [e.selectedRow.shopId]:
                                                        e.selectedRow.Id,
                                                },
                                            ]);

                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsAddFidelityClicked(false);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedFidelity([
                                            {
                                                [e.selectedRow.shopId]:
                                                    e.selectedRow.Id,
                                            },
                                        ]);

                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz  cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip> */}
                </div>
            ) : null}
            {isArchiveModalOpened ||
            (isFidelityClicked && isSupportCodeValid) ? (
                <ConfirmationArchive
                    isModalOpened={
                        isArchiveModalOpened ||
                        (isFidelityClicked && isSupportCodeValid)
                    }
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
